






















































































































































































.badge-success {
  background-color: green;
}
.badge-danger {
  background-color: red;
}
.winLoss-content {
  padding: 20px 20px 0px 20px;
  .winLoss {
    width: 100%;
    h2 {
      color: rgba(153, 164, 176, 0.8);
    }
    .winLoss-search {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 210px;
        margin-right: 5px;
        margin-left: 5px;
        label {
          color: rgba(153, 164, 176, 0.8);
        }
        .custom-select {
          border: 1px solid rgb(45, 48, 53);
          background: rgba(84, 86, 88, 0.452)
            url('~@/assets/images/home/arrow.png') right 0.75rem center/8px 10px
            no-repeat;
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: rgba(84, 86, 88, 0.452)
              url('~@/assets/images/home/arrow.png') right 0.75rem center/8px
              10px no-repeat;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
        button {
          height: 100%;
          width: 7.25rem;
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #458802, #7bc514);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
      }
    }
  }
  table {
    color: white;
    position: relative;
    thead {
      tr {
        th {
          border: none;
          position: sticky;
          top: 0;
          z-index: 10;
          background: #5da000;
        }
      }
    }
    tbody {
      tr {
        border: none;
        &:nth-child(odd) {
          color: rgb(185, 181, 181);
          background-color: rgba(90, 84, 84, 0.288);
        }
        &:nth-child(even) {
          color: rgb(223, 223, 226);
        }
        td {
          border: none;
        }
      }
    }
  }
  .table-responsive {
    height: auto;
    max-height: 600px;
    min-height: 600px;
    overflow-y: auto;
    border-radius: 10px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    //   &::-webkit-scrollbar-thumb {
    //     background: rgb(60, 255, 0);
    //     border-radius: 10px;
    //   }
  }
  .table-hover tbody tr:hover {
    color: rgb(59, 57, 57);
    background-color: rgba(255, 252, 252, 0.89);
  }
  .block-section {
    color: rgba(153, 164, 176, 0.8);
  }
}
